@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

html {
    font-family: 'Lexend', sans-serif !important;
}


body {
  font-family: 'Lexend', sans-serif !important;
}
